@import 'styles/variables.scss';

.main-header {
  height: 3.1rem;
  width: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .toc-overlay, .toc-dropdown, .toc-button-arrow-wrapper {
    transition: opacity 0.5s;
  }
  .toc-overlay:not(.is-visible), .table-of-contents:not(.is-visible) .toc-dropdown {
    pointer-events: none;
    opacity: 0;
  }
  .toc-overlay {
    position: fixed;
    z-index: 30;
    top: 3.1rem;
    background-color: rgba(255, 255, 255, 0.95);
    height: calc(100% - 3.1rem);

    @media #{$wideScreen} {
      left: 3.1rem;
      width: calc(100% - 3.1rem);
    }
    @media #{$narrowScreen} {
      left: 0;
      width: 100%;
    }

    .toc-close-button-wrapper {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 4.1rem;
      right: 1rem;

      @media #{$desktopDevice} {
        display: none;
      }
      @media #{$mobileDevice} {
        display: block;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .table-of-contents {
    position: absolute;
    // width: 30rem;
    z-index: 31;

    @media #{$wideScreen} {
      right: 43rem;
      top: 3.1rem;

      .toc-button {
        width: 4.2rem;
        height: 2.1rem;
        border-radius: 3rem;

        .toc-button-arrow-wrapper {
          top: -0.5rem;
          left: 0.6rem;

          img {
            width: 3rem;
          }
        }
      }
      .toc-dropdown {
        left: 2.1rem;
      }
    }
    @media #{$narrowScreen} {
      left: 1rem;
      top: 6.2rem;

      .toc-button {
        width: 3rem;
        height: 1.5rem;
        border-radius: 1.5rem;

        .toc-button-arrow-wrapper {
          top: -0.3rem;
          left: 0.5rem;

          img {
            width: 2rem;
          }
        }
      }
      .toc-dropdown {
        left: 1.5rem;
      }
    }

    @media #{$desktopDevice} {
      a {
        padding: 0.3rem 0;
        width: 15rem;
        font-size: 0.8rem;
      }
      .toc-wrapper .toc-dropdown {
        padding-top: 5rem;
      }
    }
    @media #{$mobileDevice} {
      a {
        padding: 0.3rem 0;
        width: 6.5rem;
        font-size: 0.7rem;
      }
      .toc-wrapper .toc-dropdown {
        padding-top: 3rem;
      }
    }

    .toc-wrapper {
      position: relative;
      height: 100%;

      .toc-button {
        position: absolute;
        cursor: pointer;
        // right: 43rem - 3rem;
        top: 0rem;
        background-color: $tocBlue;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        z-index: 1;

        .toc-button-arrow-wrapper {
          position: absolute;
        }
      }

      .toc-dropdown {
        border-left: 1px solid $tocBlue;
        padding-left: 1rem;
        position: absolute;
        top: 0rem;
        z-index: 0;

        .toc-links {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;

          @media #{$wideScreen} {
            max-height: 80vh;
          }
          @media #{$narrowScreen} {
            max-height: 70vh;
            width: 14rem;
          }

          a {
            display: block;
            text-decoration: none;
            color: black;
            opacity: 0.8;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .back-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .back-button-wrapper {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      width: 3rem;

      &:hover {
        opacity: 0.7;
      }

      .back-button-image {
        height: 0.9rem;
      }
    }
  }

  .main-title {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;

    &.interaction-disabled {
      pointer-events: none;
    }

    @media #{$narrowScreen} {
      font-size: 0.7rem;
      letter-spacing: 3px;
      padding: 0rem 0.8rem;
      text-align: right;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      & > div {
        display: block;
        line-height: 1.4;
      }
      .colon {
        display: none;
      }
      .henrybuilt.long-title {
        display: none;
      }
    }
    @media #{$wideScreen} {
      letter-spacing: 3px;
      font-size: 0.8rem;
      padding: 1rem;
      padding-right: 14rem;
      justify-content: flex-end;

      & > div {
        display: inline;
      }

      .henrybuilt.short-title {
        display: none;
      }
    }

    .open-door, .colon {
      color:#FFFFFF;
    }
    .henrybuilt {
      color: rgba(195, 195, 195, 1);
    }
  }
}
