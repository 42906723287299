.reference-icons {
  display: flex;
  flex-wrap: wrap;
  max-width: 1.5rem;
  position: absolute;
  right: 0rem;

  &.type-subtopic-document {
    top: 2.5rem;
  }

  &.type-slideshow {
    top: auto;
    bottom: 1.125rem;
    left: 1.9rem;
    max-width: 8rem;
    max-height: 1.5rem;
    background-color: transparent;

    .reference-icon {
      margin-right: 0.5rem;
    }
  }

  .reference-icon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    z-index: 20;
    cursor: pointer;

    // &:not(:hover) {
    //   opacity: 0.5;
    // }

    img {
      width: 100%;

      image-rendering: optimizeSpeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
      image-rendering: -moz-crisp-edges;          /* Firefox                        */
      image-rendering: -o-crisp-edges;            /* Opera                          */
      image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
      image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
      -ms-interpolation-mode: nearest-neighbor;   /* IE8+ */
    }
  }
}

.document-page.showing-overlay {
  .reference-icons {
    z-index: 45;
  }
}
