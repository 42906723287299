@import 'styles/variables.scss';

.subtopic-document {
  @include left-padded-page;

  .subtopic-document-wrapper {
    max-width: 40rem;
    padding-bottom: 7rem;

    .subtopic-document-title, .subtopic-title {
      font-weight: bold;
    }
    .subtopic-document-title {
      font-size: 2rem;
      text-transform: uppercase;
      margin-bottom: 0.7rem;
    }
    .subtopics-container {
      .subtopic-container {
        position: relative;
        padding-right: 3rem;
        padding-top: 2.5rem;

        &.has-brand-icons:not(.has-title) {
          @media #{$narrowScreen} {
            padding-top: 1rem;

            .reference-icons {
              top: 0.9rem;
              max-width: 6rem;

              .reference-icon {
                margin-left: 0.2rem;
              }
            }
          }
        }
        &:not(.has-title) {
          @media #{$wideScreen} {
            padding-top: 0rem;

            .subtopic-body {
              max-width: calc(100% - 3rem);
            }
            .reference-icons {
              top: 0rem;
              max-width: 6rem;

              .reference-icon {
                margin-left: 0.2rem;
              }
            }
            .brand-icons {
              top: 0.15rem;
            }
          }
        }

        .brand-icons {
          @media #{$narrowScreen} {
            margin-bottom: 0.4rem;
          }
          @media #{$wideScreen} {
            position: absolute;
            right: calc(100% + 2rem);
            top: 2.75rem;
            white-space: nowrap;
            overflow: visible;
            flex-direction: row-reverse;
          }
        }

        .subtopic-title {
          font-size: 1.2rem;
          margin-bottom: 0.6rem;
        }
        .subtopic-body {
          opacity: 0.7;

          a {
            color: inherit;
          }
        }

        @media #{$mobileDevice} {
          .subtopic-body {
            font-size: 0.8rem;
            line-height: 1.4;
          }
        }
        @media #{$desktopDevice} {
          .subtopic-body {
            font-size: 0.9rem;
            line-height: 1.6;
          }
        }
      }
    }
  }
}
