@import 'styles/variables.scss';

.index-page {
  overflow: auto;
  overflow-y: overlay; //WARNING apparently deprecated, but works
  height: 100%;

  .links-container {
    @include left-padded-page;

    padding-bottom: 7rem;
    height: auto !important;

    .index-message {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .searchterm-form {
      margin-top: 3.5rem;
      display: flex;
      flex-direction: column;
      opacity: 0.6;
      font-size: 0.9rem;
      color: $blue;
      font-weight: bold;

      .searchterm-form-text {
        margin-top: 1rem;
        max-width: 38rem;

        div:not(:first-child) {
          margin-top: 1rem;
        }

        a {
          color: inherit;
        }
      }
      .searchterm-form-input {
        border-bottom: 1px solid $blue;
        border-radius: 0px;
        width: 10rem;
        margin-top: 1rem;

        input {
          font-size: 16px;
          padding: 0.5rem 0rem;
        }
      }
      .searchterm-form-submit {
        margin-top: 1rem;
        cursor: pointer;

        span {
          bottom: 0.05rem;
          left: 0.1rem;
          position: relative;
          opacity: 0.6;
        }
      }
    }

    .link-group {
      margin-bottom: 3rem;

      .link-group-header {
        margin-top: 0.4rem;
        opacity: 0.7;
        font-size: 0.8rem;
        text-transform: lowercase;

        .recent-documents-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 8rem;

          .show-all-button {
            cursor: pointer;
            opacity: 0.8;

            span {
              bottom: 0.05rem;
              left: 0.1rem;
              position: relative;
              opacity: 0.6;
            }
          }
        }
      }
      .link-group-documents, .link-group-document-subtopics  {
        display: flex;
        flex-direction: column;

        .link-group-document {
          position: relative;
          max-width: 38rem;

          .brand-icon {
            opacity: 0.5;
          }

          @media #{$narrowScreen} {
            margin-top: 2rem;

            .brand-icons {
              // display: none;
              margin-bottom: 0.4rem;
            }
          }
          @media #{$wideScreen} {
            margin-top: 1.3rem;

            .brand-icons {
              position: absolute;
              right: calc(100% + 2rem);
              top: 0.25rem;
              white-space: nowrap;
              overflow: visible;
              flex-direction: row-reverse;
            }
          }

          .link-group-document-title {
            font-size: 1.2rem;
            font-weight: bold;
          }
          .link-group-document-title, .link-group-subtopic {
            text-decoration: none;
            color: #000000;
          }
          .link-group-document-subtopics {
            // margin-top: -0.15rem;
            // padding-left: 1rem;
            // text-transform: lowercase;

            .link-group-subtopic {
              padding-top: 0.3rem;
              font-size: 0.85rem;

              .subtopic-text-container {
                display: inline;
                line-height: 1.6;

                a {
                  color: inherit;
                }

                .subtopic-title {
                  font-weight: bold;
                  vertical-align: middle;

                  @media #{$wideScreen} {
                    display: inline-block;
                    min-width: 10rem;
                  }

                  &:not(:hover) {
                    text-decoration: none;
                  }
                }
                .subtopic-body {
                  opacity: 0.5;
                  display: block;

                  p {
                    display: inline;

                    &:not(:first-child) { //HINT only show first paragraph
                      display: none;
                    }
                  }
                  ul {
                    display: none;
                  }

                  &.truncated-body {
                    vertical-align: middle;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    @media #{$wideScreen} {
                      white-space: nowrap;
                      max-width: 80ch;
                    }
                    @media #{$narrowScreen} {
                      max-height: 5em;

                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
