.overlay-background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 40;

  .overlay-medium-wrapper {
    width: 90%;
    height: 80%;
    position: absolute;
    left: 5%;
    top: 10%;
  }

  .overlay-medium, .overlay-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-position: center;
  }
}
