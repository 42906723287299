@import 'styles/variables.scss';

html {
  font-size: 95%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

body, html, #root, .app, .hb-app-wrapper, .hb-content {
  height: 100%;
  width: 100%;
}

body, a, input {
  font-family: $fontSans;
}

.app {
  background-color: #FFF;
  
  .lower-screen {
    height: calc(100% - 3.1rem);
    width: 100%;
    position: relative;

    .permission-denied-message-container {
      width: 100%;
      height: 100%;
      padding: 7rem 3.1rem;

      .permission-denied-message {
        // text-align: center;
        font-size: 0.9rem;
        max-width: 30rem;
        line-height: 1.4;

        a {
          color: inherit;
        }

        text-decoration: none;
      }
    }

    .main-content {
      position: absolute;
      right: 0;

      @media #{$wideScreen} {
        width: calc(100% - 3.1rem);
        top: 0;
        height: 100%;
      }
      @media #{$narrowScreen} {
        width: 100%;
        top: 3.1rem;
        height: calc(100% - 3.1rem);
      }
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
// div::-webkit-scrollbar {
//   display: none;
// }

/* Hide scrollbar for IE and Edge */
div {
  -ms-overflow-style: none;
}
