@import 'styles/variables.scss';

.slideshow {
  height: 100%;
  width: 100%;
  background-color: #000000;

  &.type-video {
    display: flex;
    flex-direction: column;
  }
  &:not(.type-video) {
    .slideshow-media-container {
      position: absolute;
      top: 0;
      left: 0;
    }
    .slideshow-tray {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .slideshow-media-container {
    position: relative;
    height: 100%;
    width: 100%;

    .slideshow-medium {
      position: absolute;
      top: 0;
      left: 0;

      @media #{$narrowScreen} {
        &.type-image {
          background-size: contain;
        }
        &.type-vimage {
          background-size: cover;
        }
      }
      @media #{$wideScreen} {
        &.type-image {
          background-size: cover;
        }
        &.type-vimage {
          background-size: contain;
        }
      }

      &.type-image, &.type-vimage, &.type-video {
        &.active {
          opacity: 1;
          z-index: 6;
        }
      }
      &.type-image, &.type-vimage {
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.8s;
        position: absolute;
        margin: auto;
        cursor: pointer;
        z-index: 0;

        .image-for-right-clicking {
          opacity: 0;
          width: 100%;
          height: 100%;
        }
      }
      &.type-video {
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.8s;
        position: absolute;
        cursor: pointer;

        .slideshow-video-player {
          height: 100%;
          object-position: center;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
}

.slideshow-tray {
  display: flex;
  width: 100%;
  min-height: 7rem;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  opacity: 1;
  flex-grow: 1;

  @media #{$desktopDevice} {
    font-size: 1rem;
    line-height: 1.6;

    &.bottom-padded {
      padding-bottom: 3.75rem;
    }
  }
  @media #{$mobileDevice} {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  @media #{$narrowScreen} {
    flex-direction: column;
    padding: 1rem 1rem;

    .slideshow-caption {
      padding-bottom: 1rem;
    }
    .slideshow-button {
      width: 2rem;
      margin-left: 1rem;
    }
  }
  @media #{$wideScreen} {
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;

    .slideshow-button {
      width: 3rem;
    }
    .slideshow-index {
      margin-right: 0.5rem;
    }
  }

  &.count-1 {
    .slideshow-tray-controls {
      .left-button, .right-button, .slideshow-index {
        display: none;
      }
    }
  }
  .slideshow-caption {
    padding-right: 1rem;

    .document-title {
      font-size: 0.8em;
      margin-bottom: 0.2em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: bold;
    }
    .brand-icon {
      opacity: 0.7;

      &:first-child {
        color: white !important;
      }
    }
    .subtopic-body {
      margin-top: 0.5em;

      a {
        color: inherit;
      }
      p {
        margin-top: 0;
      }
    }
  }
  .slideshow-tray-controls {
    justify-content: flex-end;
    display: flex;

    .slideshow-index {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 3.5rem;
    }
    .slideshow-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        filter: invert(100%);
      }

      &:hover {
        .left-button, .right-button, .enter-button, .exit-button, .pinterest-button {
          opacity: 0.5;
        }
      }
    }
  }
}
