$blue: rgb(105, 139, 184);
$tocBlue: rgb(185, 213, 238);

$fontSans: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;;
$fontSerif: 'Noto Serif JP';
$fontMono: monospace;

$openSidebarWidth: 30rem;

$wideScreen: 'screen and (min-width: 801px)';
$narrowScreen: 'screen and (max-width: 800px)';

$desktopDevice: 'screen and (min-device-width: 501px)';
$mobileDevice: 'screen and (max-device-width: 500px)';

@mixin left-padded-page() {

  @media #{$wideScreen} {
    margin-left: auto;
    max-width: 43rem;
    width: 100%;
    padding-top: 7rem;
    height: 100%;
  }
  @media #{$narrowScreen} {
    height: 100%;
    padding: 3rem 1rem;
  }
}

// @media #{$narrowScreen} {

// }
// @media #{$wideScreen} {

// }
