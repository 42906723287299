@import 'styles/variables.scss';

$dotSize: 10px;
$width: 3.1rem;
$openMargin: 4rem;
$paddingTop: 7rem;

.sidebar {
  position: absolute;
  z-index: 35;
  height: 100%;

  @media #{$narrowScreen} {
    width: 100%;
  }
  @media #{$wideScreen} {
    width: $openSidebarWidth;
  }

  &:not(.is-open) {
    pointer-events: none;

    .closed-sidebar, .sidebar-toggle-button-wrapper, .blinking-dot-container {
      &:not(.interaction-disabled) {
        pointer-events: auto;
      }
    }
    .blinking-dot-container {
      display: none;
    }
  }

  .sidebar-toggle-button-wrapper {
    position: absolute;
    top: 0;
    width: $width;
    height: $width;
    padding: 0.2rem;
    z-index: 37;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }

    .sidebar-toggle-button {
      @media #{$narrowScreen} {
        width: 65%;
      }
      @media #{$wideScreen} {
        width: 100%;
      }
    }
  }

  .blinking-dot-container {
    position: absolute;
    top: calc(#{$paddingTop} + #{$dotSize/2});
    display: flex;
    justify-content: center;
    align-items: center;
    width: $width;
    z-index: 37;
    cursor: pointer;

    .blinking-dot {
      width: $dotSize;
      height: $dotSize;
      border-radius: $dotSize;
      background-color: $blue;
      opacity: 0.05;
      transition: opacity 0.64s*2 ease-out;

      &.visible {
        opacity: 1;
        transition: opacity 0.64s*2 ease-in;
      }
    }
  }

  .closed-sidebar, .open-sidebar {
    position: relative;
    display: none;
    z-index: 0;
    background-color: rgba(248, 246, 246, 1);
    // transition: opacity 0.6s ease-in;

    &.visible {
      display: block;
      z-index: 35;
    }
    .company-button, .company-menu-button-text {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      font-size: 0.9rem;
      text-transform: uppercase;
      text-decoration: none;
    }
    .company-button, .company-menu-button {
      margin-bottom: 0.3rem;
      cursor: pointer;
      font-weight: bold;

      &.selected {
        &, .company-menu-button-text {
          background-color: #000000;
          color: #FFFFFF;
        }
      }

      @media #{$desktopDevice} {
        &.selected, &:hover {
          &, .company-menu-button-text {
            background-color: #000000;
            color: #FFFFFF;
          }
        }
      }
    }
    .company-menu-button {
      width: 100%;
      padding-left: $openMargin;
      padding-right: $openMargin;

      .company-menu-button-text {
        // padding-left: 1rem;
        // background-color: rgba(164, 164, 164, 1);
        color: rgba(93, 93, 93, 1);
      }
    }
  }

  .closed-sidebar {
    @media #{$narrowScreen} {
      height: 3.1rem;
      width: 100%;

      .company-buttons {
        position: absolute;
        right: 0;
        flex-direction: row;
        align-items: center;

        .company-button {
          height: 100%;
          line-height: 3.1rem;
          margin-bottom: 0;
          padding: 0;
        }
      }
      .top-buttons, .company-buttons {
        height: 100%;
        width: 10rem;
      }
    }
    @media #{$wideScreen} {
      height: 100%;
      width: $width;

      .company-buttons {
        position: absolute;
        top: 15rem;
        right: 0;
        flex-direction: column;

        .company-button {
          width: 100%;
        }
      }
      .top-buttons, .company-buttons {
        height: 10rem;
        width: 100%;
      }
    }

    .company-buttons {
      display: flex;

      .company-button {
        width: 100%;
        color: rgba(93, 93, 93, 1);
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .open-sidebar {
    width: 100%;
    height: 100%;

    .open-sidebar-content {
      width: 100%;
      min-height: 100%;
      height: auto;
      padding-top: $paddingTop;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: rgba(248, 246, 246, 1);

      .chat-container {
        width: calc(100% - #{$openMargin});
        margin-left: $openMargin;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        .chat-message {
          font-family: $fontMono;
          opacity: 0.6;

          @media #{$mobileDevice} {
            font-size: 1.1rem;
          }
          @media #{$desktopDevice} {
            font-size: 1.2rem;
          }
        }
      }

      .search-bar {
        width: calc(100% - #{$openMargin});
        margin-left: $openMargin;
        margin-bottom: 2rem;
        position: relative;

        .text-input {
          width: calc(100% - #{$openMargin});
          border-bottom: 1px solid $blue;
          border-radius: 0px;

          input {
            width: calc(100% - #{$openMargin} - 1rem);
            background-color: transparent;
            padding: 0.5rem 0rem;
            font-size: 16px;
          }
        }
        .search-buttons {
          position: absolute;
          top: 0.6rem;
          right: 4.3rem;

          .clear-search-button-wrapper, .search-submit-button-wrapper {
            display: inline-block;
            opacity: 0;
            transition: opacity 0.2s;

            &.visible {
              opacity: 1;
              cursor: pointer;

              &:hover {
                opacity: 0.7;
              }
            }
            .clear-search-button {
              width: 1rem;
            }
            .search-submit-button {
              width: 2rem;
              opacity: 0.3;
              margin-left: 1rem;
            }
          }
          .search-submit-button-wrapper {
            @media #{$desktopDevice} {
              display: none;
            }
          }
        }
      }
    }

    .company-menu {
      width: 100%;

      .company-menu-container {
        width: 100%;

        .company-links-container {
          margin-left: $openMargin;
          padding-top: 0.2rem;
          padding-bottom: 1.2rem;
          display: none;
          flex-direction: column;
          text-transform: lowercase;
          // opacity: 0;
          // height: 0;
          // transition: height 0.3s linear 0.1s, opacity 0.2s linear 0.4s;

          &.visible {
            display: flex;
            // opacity: 1;
            // height: 4rem;
            // transition: opacity 0.3s linear 0.1s, height 0.2s linear 0.4s;
          }

          .company-menu-link {
            margin-top: 0.5rem;
            margin-left: 1rem;
            font-size: 0.9rem;
            color: #000000;
            text-decoration: none;
          }
        }
      }
    }

    .sidebar-menu-links {
      margin-left: $openMargin;
      margin-bottom: $openMargin;
      margin-top: auto;
      display: flex;
      flex-direction: column;
      padding-top: 2rem;

      a {
        margin-top: 0.6rem;
        text-decoration: none;
        opacity: 0.4;
        color: #000000;
        font-size: 0.7rem;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
