.brand-icons {
  display: flex;

  .brand-icon {
    text-transform: uppercase;
    font-size: 0.8em;
    opacity: 1;
    font-weight: bold;
    margin-right: 0.5rem;

    &:not(.active) {
      display: none;
    }
  }
}
