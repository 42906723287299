@import 'styles/variables.scss';

.shareable-link-button {
  margin-top: 1rem;
  cursor: pointer;
  font-size: 0.8rem;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
}
