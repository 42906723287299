@import 'styles/variables.scss';

.interstitial-page {
  height: 100%;
  position: relative;
  position: absolute;
  right: 0;
  z-index: 30;
  opacity: 0;
  transition: opacity 1s ease-in;

  &.active {
    opacity: 1;
  }

  @media #{$wideScreen} {
    width: calc(100% - #{$openSidebarWidth} + 3.1rem);
  }
  @media #{$narrowScreen} {
    width: 100%;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.interstitial-page-background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  opacity: 0;
  z-index: 20;

  &.active {
    opacity: 1;
  }
}
