@import 'styles/variables.scss';

.fullscreen-media-document {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: visible;

  @media #{$wideScreen} {
    padding: 5rem;
  }
  @media #{$narrowScreen} {
    padding: 3rem 1rem;
  }

  .full-document-link {
    margin-top: 0.7rem;
    opacity: 0.5;
    color: inherit;
    font-size: 0.9rem;
    display: block;
    text-decoration: none;
  }
  .fullscreen-media-title {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.7rem;

    @media #{$desktopDevice} {
      font-size: 2rem;
    }
    @media #{$mobileDevice} {
      font-size: 1.2rem;
    }
  }

  .fullscreen-media-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .fullscreen-media-subtopic {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 2.5rem;
      position: relative;
      // align-items: center;

      .subtopic-title {
        font-weight: bold;
        margin-bottom: 0.6rem;
        font-size: 1.2rem;
      }

      @media #{$mobileDevice} {
        .subtopic-body {
          font-size: 0.8rem;
          line-height: 1.4;
        }
      }
      @media #{$desktopDevice} {
        .subtopic-body {
          font-size: 0.9rem;
          line-height: 1.6;
        }
      }

      .subtopic-body {
        max-width: 40rem;
        opacity: 0.7;

        a {
          color: inherit;
        }
        p {
          margin-top: 0;
        }
      }

      .subtopic-medium {
        width: 100%;
        margin: 2rem 0;

        video {
          outline: none;
          /* override other styles to make responsive */
          height: auto !important;
          width: 100% !important;
        }

        @media #{$wideScreen} {
          height: 90vh;
          background-position: left;
          background-size: contain;
          background-repeat: no-repeat;

          img {
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
        @media #{$narrowScreen} {
          background-image: none !important; //hide background image on narrow intentionally

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
