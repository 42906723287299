@import 'styles/variables.scss';

.medium-metadata-background {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$desktopDevice} {
    width: 50%;
    right: 25%;
    top: 1rem;
  }
  @media #{$mobileDevice} {
    right: 0.5rem;
    top: 0.5rem;
    width: calc(100% - 1rem);
  }

  .medium-metadata-text {
    display: inline-block;
    background-color: black;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.4;

    .medium-metadata-caption {
      color: rgb(255, 255, 255);
      display: inline-block;
    }
    .medium-metadata-location, .medium-metadata-credit, .divider {
      color: rgb(195, 195, 195);
      display: inline-block;
    }

    @media #{$desktopDevice} {
      font-size: 0.7rem;
      padding: 1rem;
      letter-spacing: 3px;
    }
    @media #{$mobileDevice} {
      padding: 0.8rem;
      letter-spacing: 2px;
      font-size: 0.6rem;
    }
  }
}
